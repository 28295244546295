// Angular Modules
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

// External Modules
import { forkJoin, map, Observable, of, switchMap } from 'rxjs';

// Enums
import { SearchStrategy } from '@enums/search-strategy.enum';

// Models
import { QueryParameters, QueryResults } from '@models/index';
import { Device, IDeviceCardUsage } from '@equipments/devices-mgmt/models'
import { Customer } from '@customers/customers-mgmt/models';
import { Hardware } from '@configuration/hardwares/models';
import { Firmware } from '@configuration/firmwares/models';
import { Broker } from '@mqtt/brokers/models';
import { DeviceImportResult } from '../models/device-import-result.model';

// Services
import { HttpQueryUtils } from '@services/http-query-utils.service';
import { CustomerService } from '@customers/customers-mgmt/services/customer.service';
import { FirmwareService } from '@configuration/firmwares/services/firmware.service';
import { HardwareService } from '@configuration/hardwares/services/hardware.service';
import { BrokerService } from '@mqtt/brokers/services/broker.service';
import { Reception } from '@mqtt/receptions/models';
import { Request } from '@mqtt/requests/models';
import { QueryParameter } from '@models/api-request.model';
import { DEVICE_INSTALLATION_STATE_TYPE } from '@enums/device-installation-state-type.enum';


const BACKEND_API = `${environment.apiBaseUrl}`;
const ENTITY = 'device'

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  constructor(
    private http: HttpClient,
    public httpQueryUtils: HttpQueryUtils,
    private customerService: CustomerService,
    private firmwareService: FirmwareService,
    private hardwareService: HardwareService,
    private brokerService: BrokerService,

  ) { }

  // get httpParams() {
  //   return new HttpParams()//.set('fields', 'name,capital,alpha2Code,flags.png,population');
  // }


  find(queryParameters: QueryParameters, strategy?: SearchStrategy): Observable<QueryResults<Device>> {

    const url = `${BACKEND_API}/${ENTITY}/`;

    let queryResults$ = this.httpQueryUtils.executeQuery<Device>(url, queryParameters);

    if (strategy === SearchStrategy.EAGER) {

      queryResults$ = queryResults$.pipe(
        switchMap((queryResults: QueryResults<Device>) => {

          // return empty Observable in case of no items
          if (queryResults.items.length === 0) return of(queryResults);

          return this.fillRelatedAttributes(queryResults.items)
            .pipe(
              map(() => queryResults))
        })
      )
    }

    return queryResults$;
  }


  findById(id: number, strategy?: SearchStrategy): Observable<Device> {

    const url = `${BACKEND_API}/${ENTITY}/${id}/`;
    let result$ = this.httpQueryUtils.executeQuery<Device>(url, {})
      .pipe(
        map(result => result.items[0])
      )

    if (strategy === SearchStrategy.EAGER) {
      result$ = result$.pipe(
        switchMap((itemParent: Device) => {

          return this.fillRelatedAttributes([itemParent])
          .pipe(
            map((items) => items[0]))

        })
      )

    }
    return result$;
  }


  private fillRelatedAttributes(items: Device[]) {

    const requestDetail_1: Observable<Customer>[] = this.getDetailObservablesCustomer(items);
    const requestDetail_2: Observable<Firmware>[] = this.getDetailObservablesFirmware(items);
    const requestDetail_3: Observable<Hardware>[] = this.getDetailObservablesHardware(items);
    const requestDetail_4: Observable<Broker>[] = this.getDetailObservablesBroker(items);

    // la caña de España!
    return forkJoin([...requestDetail_1, ...requestDetail_2, ...requestDetail_3, ...requestDetail_4])
      .pipe(
        map((arrayWithDetailData: any) => {

          const arrLength = items.length;

          const resultDetail_1 = arrayWithDetailData.slice(0, arrLength);
          const resultDetail_2 = arrayWithDetailData.slice(arrLength, arrLength * 2);
          const resultDetail_3 = arrayWithDetailData.slice(arrLength * 2, arrLength * 3);
          const resultDetail_4 = arrayWithDetailData.slice(arrLength * 3);

          items
            .map((item, i) => {

              item.customer = resultDetail_1[i];
              item.firmware = resultDetail_2[i];
              item.hardware = resultDetail_3[i];
              item.broker = resultDetail_4[i];

              return item;
            });

          return items;
        })
      );
  }


  /**
   *
   * @param items array of items
   * @returns Array of Observables for get detailed data (findById)
   */
  private getDetailObservablesCustomer(items: Device[]): Observable<Customer>[] {

    return items.map((item: Device) => {

      if (item.customerId && item.customerId > 0) {
        return this.customerService.findById(item.customerId)
      } else {
        return of(new Customer());
      }

    });

  }

  /**
   *
   * @param items array of items
   * @returns Array of Observables for get detailed data (findById)
   */
  private getDetailObservablesFirmware(items: Device[]): Observable<Firmware>[] {

    return items.map((item: Device) => {

      if (item.firmwareId && item.firmwareId > 0) {
        return this.firmwareService.findById(item.firmwareId)
      } else {
        return of(new Firmware());
      }

    });

  }

  /**
   *
   * @param items array of items
   * @returns Array of Observables for get detailed data (findById)
   */
  private getDetailObservablesHardware(items: Device[]): Observable<Hardware>[] {

    return items.map((item: Device) => {

      if (item.hardwareId && item.hardwareId > 0) {
        return this.hardwareService.findById(item.hardwareId)
      } else {
        return of(new Hardware());
      }

    });

  }

  /**
   *
   * @param items array of items
   * @returns Array of Observables for get detailed data (findById)
   */
  private getDetailObservablesBroker(items: Device[]): Observable<Broker>[] {

    return items.map((item: Device) => {

      if (item.brokerId && item.brokerId > 0) {
        return this.brokerService.findById(item.brokerId)
      } else {
        return of(new Broker());
      }

    });

  }

  delete(id: number): Observable<any> {

    const url = `${BACKEND_API}/${ENTITY}/${id}/`;

    return this.http.delete(url);
  }

  deleteBulk(ids: Array<number>): Observable<any> {

    const colObservablesToDelete = [];

    for (const id of ids) {
      colObservablesToDelete.push(this.delete(id));
    }

    return forkJoin(colObservablesToDelete);
  }


  add(entity: Device): Observable<Device> {

    const url = `${BACKEND_API}/${ENTITY}/`;

    return this.http.post<Device>(url, entity);
  }


  update(entity: Device): Observable<Device> {

    const url = `${BACKEND_API}/${ENTITY}/${entity.id}/`;

    return this.http.put<Device>(url, entity);
  }


  /**
   * IMPORT
   * @param file
   * @returns Observable<DeviceImportResult>
   */
  import(file: File): Observable<DeviceImportResult> {

    const url = `${BACKEND_API}/${ENTITY}/import/`;

    const formData = new FormData();
    formData.append('multipartFile', file, file.name);

    // const headers = new HttpHeaders({
    //   'Content-Type': 'multipart/form-data' // Establecer el tipo de contenido como multipart/form-data
    // });

    const requestOptions = {
      // headers: headers,
      withCredentials: true,
    };

    return this.http.post<DeviceImportResult>(url, formData, requestOptions);

  }

  applyFirmware(deviceId: number, firmwareId: number): Observable<Device> {

    const url = `${BACKEND_API}/${ENTITY}/${deviceId}/firmware/${firmwareId}`;

    return this.http.get<Device>(url);
  }

  changeInstallationStateType(deviceId: number, deviceInstallationStateType: DEVICE_INSTALLATION_STATE_TYPE): Observable<Device> {

    const url = `${BACKEND_API}/${ENTITY}/${deviceId}/deviceInstallationState/${deviceInstallationStateType}`;

    return this.http.get<Device>(url);
  }

  getDeviceCardUsage(deviceId: number, queryParameters: QueryParameters): Observable<QueryResults<IDeviceCardUsage>> {

    const url = `${BACKEND_API}/${ENTITY}/${deviceId}/deviceCardUsage`;

    return this.httpQueryUtils.executeQuery<IDeviceCardUsage>(url, queryParameters);
  }

  getDeviceAlert(deviceId: number): Observable<any> {

    const url = `${BACKEND_API}/${ENTITY}/${deviceId}/deviceAlert`;

    return this.http.get<any>(url);
  }

  getDeviceRequest(deviceId: number): Observable<Request[]> {

    const url = `${BACKEND_API}/${ENTITY}/${deviceId}/request`;

    return this.http.get<Request[]>(url);
  }

  getDeviceReception(deviceId: number, queryParameters: QueryParameters): Observable<QueryResults<Reception>> {

    const url = `${BACKEND_API}/${ENTITY}/${deviceId}/reception`;

    return this.httpQueryUtils.executeQuery<Reception>(url, queryParameters);
  }

}
